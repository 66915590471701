


<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f"
      spinner="spinner"
    />

    <v-layout wrap pt-4>
      <v-flex xs12>
        <v-layout
          wrap
          justify-center
          style="font-family: mainfont; font-size: 16px; color: black"
        >
          <v-flex xs12 lg12 pa-5>
            <v-card elevation="0" class="pa-8">
              <v-layout wrap justify-center>
                <v-flex lg8 xs12>
                  <v-layout wrap>
                    <v-flex
                      xs11
                      pb-5
                      class="mainfont"
                      style="color: black; font-size: 15px; font-weight: bold"
                      >User info</v-flex
                    >
                    <v-flex xs3 lg3>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;

                          color: #515151;
                          font-weight: bold;
                        "
                        >ORDER  BY</span
                      >
                    </v-flex>
                    <v-flex xs3 lg3>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;
                          color: #515151;
                          font-weight: bold;
                        "
                        >ORDER ID</span
                      >
                    </v-flex>
                    <v-flex xs3 lg3>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;

                          color: #515151;
                          font-weight: bold;
                        "
                        >ORDER DATE</span
                      >
                    </v-flex>
                    <v-flex xs3 lg3>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;

                          color: #515151;
                          font-weight: bold;
                        "
                        >PHONE</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="list && list.uid">
                    <v-flex xs3 v-if="list.uid">
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                      >
                        {{ list.uid.name }}
                      </span>
                    </v-flex>

                    <v-flex xs3 v-if="list && list.orderID">
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                      >
                        {{ list.orderID }}
                      </span>
                    </v-flex>

                    <v-flex xs3>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        v-if="list && list.create_date"
                      >
                        {{ formatDate(new Date(list.create_date)) }}
                      </span>
                    </v-flex>

                    <v-flex xs3 v-if="list && list.uid && list.uid.phone">
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                      >
                        {{ list.uid.phone }}
                      </span>
                    </v-flex>
                  </v-layout>

                  <v-flex xs12 pt-6 pr-8>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-layout pt-6 wrap justify-start>
                    <v-flex
                      xs11
                      pb-5
                      class="mainfont"
                      style="color: black; font-size: 15px; font-weight: bold"
                      >Shipping details</v-flex
                    >
                    <v-flex xs4>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;

                          color: #515151;
                          font-weight: bold;
                        "
                      >
                         NAME</span
                      >
                    </v-flex>
                    <v-flex xs4>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;

                          color: #515151;
                          font-weight: bold;
                        "
                        >ADDRESS</span
                      >
                    </v-flex>
                    <v-flex xs4>
                      <span
                        style="
                          font-family: mainfont;
                          font-size: 13px;
                          color: #515151;
                          font-weight: bold;
                        "
                        >CONTACT NUMBER</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start>
                    <v-flex xs4 v-if="list">
                      <span v-if="list.deliveryAddress">
                        <span
                          style="
                            font-family: mainfont;
                            font-size: 14px;
                            color: black;
                          "
                          v-if="list.deliveryAddress.name"
                        >
                          {{ list.deliveryAddress.name }}
                        </span>
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list">
                      <span v-if="list.deliveryAddress">
                        <span
                          style="
                            font-family: mainfont;
                            font-size: 14px;
                            color: black;
                          "
                          v-if="list.deliveryAddress.address"
                        >
                          {{ list.deliveryAddress.address }}
                        </span>
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list">
                      <span v-if="list.deliveryAddress">
                        <span
                          style="
                            font-family: mainfont;
                            font-size: 14px;
                            color: black;
                          "
                          v-if="list.deliveryAddress.phone"
                        >
                          {{ list.deliveryAddress.phone }}
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 lg4>
                  <v-layout wrap>
                    <v-flex xs12 pr-3 v-if="list.orderStatus === 'Cancelled'">
                      <v-layout wrap>
                        <v-flex align-self-center xs12 pb-2 text-center>
                          <span
                            class="mainfont"
                            style="
                              color: red;
                              font-size: 18px;
                              font-weight: bold;
                            "
                          >
                            Order Cancelled !
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      pr-3
                      v-else-if="list.orderStatus === 'Delivered'"
                    >
                      <v-layout wrap>
                        <v-flex align-self-center pb-2 xs6 text-left>
                          <span
                            class="mainfont"
                            style="
                              color: #0a2e2d;
                              font-size: 18px;
                              font-weight: bold;
                            "
                          >
                            Order Delivered
                          </span></v-flex
                        >
                        <v-flex
                          xs6
                          pl-4
                          pb-3
                          class="mainfont"
                          style="color: black; font-size: 14px"
                        >
                          <v-btn
                            height="37px"
                            dark
                            class="no-uppercase"
                            color="#13736f"
                            @click="downloadInvoice"
                            ><span
                              style="
                                font-family: mainfont;
                                font-size: 12px;
                                color: white;
                                letter-spacing: 1px;
                                cursor: pointer;
                              "
                              >Download Invoice
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 pr-3 v-else-if="list.orderStatus === 'Placed'">
                      <v-layout wrap>
                        <v-flex xs4 pt-3>
                          <span
                            class="mainfont"
                            style="
                              color: black;
                              font-size: 15px;
                              font-weight: bold;
                            "
                            >Delivery Status</span
                          ></v-flex
                        >
                        <v-flex align-self-center pl-4 pb-4 xs8 text-center>
                          <v-autocomplete
                            outlined
                            @input="changeOrderstatus()"
                            dense
                            :items="itemArray"
                            v-model="list.orderStatus"
                            color="black"
                            item-text="name"
                            item-color="#FF1313"
                            hide-details
                            clearable
                            class="custom-autocomplete rounded-lg mainfont"
                          >
                            <span>gu</span>
                            <template v-slot:append>
                              <v-icon color="black">mdi-chevron-down</v-icon>
                            </template>
                            <!-- <template v-slot:label>
                          <span class="custom-label-text">Role</span>
                        </template> -->
                          </v-autocomplete></v-flex
                        >
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      pr-3
                      v-else-if="list.orderStatus === 'Shipped'"
                    >
                      <v-layout wrap>
                        <v-flex xs4 pt-3>
                          <span
                            class="mainfont"
                            style="
                              color: black;
                              font-size: 15px;
                              font-weight: bold;
                            "
                            >Delivery Status</span
                          ></v-flex
                        >
                        <v-flex align-self-center pl-4 pb-4 xs8 text-center>
                          <v-autocomplete
                            outlined
                            @input="changeOrderstatus()"
                            dense
                            :items="filteredItemArray"
                            v-model="list.orderStatus"
                            color="black"
                            item-text="name"
                            item-color="#FF1313"
                            hide-details
                            clearable
                            class="custom-autocomplete rounded-lg mainfont"
                          >
                            <span>gu</span>
                            <template v-slot:append>
                              <v-icon color="black">mdi-chevron-down</v-icon>
                            </template>
                            <template v-slot:label>
                              <span class="custom-label-text"
                                >Change Status</span
                              >
                            </template>
                          </v-autocomplete></v-flex
                        >
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-card class="pa-4 sharpEdges mainbg4" elevation="0">
                    <v-layout wrap justify-start>
                      <v-flex xs12 text-left>
                        <span style="font-weight: bold"> Order Details</span>
                      </v-flex>
                      <v-flex
                        xs6
                        pt-2
                        text-left
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Price</v-flex
                      >
                      <v-flex
                        xs5
                        pt-2
                        text-right
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Rs&nbsp;{{ list.itemTotalAmount }}</v-flex
                      >
                      <!-- <v-flex
                        xs6
                        pt-1
                        text-left
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >promocode</v-flex
                      > -->
                      <!-- <v-flex
                        xs5
                        pt-1
                        text-right
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Rs&nbsp;40</v-flex
                      > -->
                      <v-flex
                        xs7
                        pt-1
                        text-left
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Delivery charge</v-flex
                      >
                      <v-flex
                        xs4
                        pt-1
                        text-right
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Rs&nbsp;{{ list.deliveryCharge }}
                      </v-flex>
                      <v-flex
                        xs6
                        pt-1
                        text-left
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Total Tax</v-flex
                      >
                      <v-flex
                        xs5
                        pt-1
                        text-right
                        style="
                          font-family: mainfont;
                          font-size: 14px;
                          color: black;
                        "
                        >Rs&nbsp;{{ list.taxAmount }}</v-flex
                      >
                      <v-flex xs6 pt-1 text-left style="font-weight: bold"
                        >Amount Paid</v-flex
                      >
                      <v-flex xs5 text-right style="font-weight: bold"
                        >Rs&nbsp;{{ list.orderTotal }}</v-flex
                      >
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout pb-5 justify-center wrap fill-height>
      <v-flex
        pb-3
        v-for="(item, i) in items"
        :key="i"
        xs12
        pl-6
        pr-3
        align-self-center
      >
        <v-card style="border-radius: 15px">
          <v-layout wrap justify-center fill-height>
            <v-flex xs1 pl-5 pt-5 pb-5 align-self-center>
              <v-avatar size="80px">
                <v-img :src="mediaURLnew + item.productId.photos[0]"> </v-img>
              </v-avatar>
            </v-flex>
            <v-flex xs11 pl-5 align-self-center>
              <v-layout wrap>
                <v-flex xs12 pt-3 pl-8 align-self-center>
                  <v-layout wrap fill-height>
                    <v-flex xs2 align-self-center class="popfont"
                      >PRODUCT NAME</v-flex
                    >

                    <v-flex xs1 align-self-center class="popfont">QTY</v-flex>
                    <v-flex xs2 align-self-center class="popfont"
                      >WEIGHT</v-flex
                    >
                    <v-flex xs2 align-self-center class="popfont"
                      >AMOUNT</v-flex
                    >
                    <v-flex xs2 align-self-center class="popfont"
                      >TOTAL WEIGHT
                    </v-flex>
                    
                  </v-layout>
                </v-flex>
                <v-flex xs12 pl-8>
                  <v-layout wrap>
                    <v-flex xs2 class="fontpop2">{{ item.productName }}</v-flex>
                    <v-flex xs1 class="fontpop2">{{ item.quantity }}</v-flex>
                    <v-flex xs2 class="fontpop2"
                      >{{ item.weight }}&nbsp;{{ item.weightType }}</v-flex
                    >
                    <v-flex xs2 class="fontpop3"
                      >Rs&nbsp;{{ item.totalAmount }}</v-flex
                    >
                    <v-flex xs2 class="fontpop2">{{item.totalWeight}}&nbsp;{{ item.weightType }}</v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
import { mdiChevronDown } from "@mdi/js";

export default {
  data() {
    return {
      itemArray: ["Cancelled", "Shipped", "Delivered"],
      title: null,
      content: [],
      photos: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dialog2: false,
      deletedialog: false,

      imageArray: [],
      imageError: "",
      editdailog: false,
      list: {},
      imageCoverError: "",
      formData: new FormData(),
      formData2: new FormData(),
      id: this.$route.query.id,
      items: [],
      selectedFiles: [],
      curid: [],
      image: null,
      coverImageArray: [],
      role: null,
      appLoading: false,
      list2: {
        orderStatus: "Shipped",
      },
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
    };
  },
  mounted() {
    this.getlist();
  },
  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
    filteredItemArray() {
      if (this.list.orderStatus === "Shipped") {
        return this.itemArray.filter((item) => item === "Delivered");
      } else {
        return this.itemArray;
      }
    },
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getlist() {
      this.appLoading = true;
      axios({
        method: "GET",

        url: "/admin/order/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.items = response.data.items;

          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    downloadInvoice() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/order/invoice/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          orderId: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoice.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    changeOrderstatus() {
      this.appLoading = true;
      axios({
        method: "POST",

        url: "/admin/order/status/change",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderId: this.id,
          orderStatus: this.list.orderStatus,
        },
      });
      this.$router.push({
        path: "/StorePage",
      });
      this.appLoading = false;
    },
  },
};
</script>
  <style>
.icon {
  padding-left: 170px;
}

.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.sharpEdges {
  border-radius: 0px;
  /* Add any other styles for the card here if needed */
}
.height-wrapper {
  height: 75px; /* Set the desired height (40px in this example) */
  display: flex;
  align-items: center; /* Vertically center the content */
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus {
  background-color: #13736f;
}
</style>